//@ts-check
import { getPostTranslation, updatePost } from '@/api/post'
import { useToast } from '@/composables/toast'
import {
  getDataURL,
  getFileType,
  sliceThrehold,
  useUploadMedia,
} from '@/composables/upload_media'
import { useArtworkStore } from '@/pinia/artwork'
import {
  convertClientPricesToServerPrices,
  convertClientPriceToServerPrice,
  convertServerPricesToClientPrices,
} from '@/utils'
import { MediaType } from '@perohub/libpero/dist/types'
import { useSortable } from '@vueuse/integrations/useSortable'
import { computed, onMounted, ref, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRoute, useRouter } from 'vue-router'
import { useArtworkWrite } from './artwork_write'

export const useArtworkEdit = function () {
  const artworkStore = useArtworkStore()
  const artworkId = String(useRoute().params.postId)
  const updating = ref(false)
  const title = ref('')
  const content = ref('')
  const medias = ref([])
  const mediasContainerRef = ref(null)
  const mediaInputRef = ref(null)
  const router = useRouter()
  const { uploads, uploadOne, uploadSlicedOne, progress, uploading, total } =
    useUploadMedia()
  const selectedIndex = ref(-1)
  const { Toast } = useToast()
  const useWatermark = ref(null)
  const showWatermarkPositionsPicker = ref(false)
  const watermarkPositions = ref([])
  const isR18 = ref(false)
  const collectible = ref(false)
  const titleLocales = ref({})
  const contentLocales = ref({})
  const localeEnabled = ref(false)
  const defaultLocale = ref(null)
  const hideStars = ref(false)
  const { textMedias, addTextMedia, concludeTextMedias } = useArtworkWrite({
    mediasRef: medias,
  })
  const priceInfo = ref({
    defaultCurrency: 'JPY',
    prices: {
      CNY: 0,
      JPY: 0,
      USD: 0,
      EUR: 0,
    },
    price: 0,
  })
  const { t } = useI18n()

  const immutableArtwork = computed(() => artworkStore.artworks[artworkId])

  watch(immutableArtwork, async () => {
    if (immutableArtwork.value) {
      initScopedArtwork()
    }
  })

  watch(useWatermark, (_, oldUseWatermark) => {
    if (oldUseWatermark !== null) {
      showWatermarkPositionsPicker.value = useWatermark.value
    }
  })

  const isFree = computed(() => {
    if (!immutableArtwork.value) {
      return true
    }

    return immutableArtwork.value.prices['USD'] <= 0
  })

  const allMediasPrepared = computed(() => {
    return medias.value.every((media) => {
      return media.path !== null
    })
  })

  const buttonText = computed(() => {
    if (uploading.value) {
      return t('uploading')
    }

    if (updating.value) {
      return t('loading')
    }

    if (!allMediasPrepared.value) {
      return t('mediaNotReady')
    }

    return t('edit')
  })

  const initScopedArtwork = async function () {
    try {
      titleLocales.value = immutableArtwork.value.titleLocales

      try {
        const _contentLocales = await getPostTranslation(artworkId)
        artworkStore.attachTranslations(artworkId, _contentLocales)
        if (immutableArtwork.value.contentLocales) {
          contentLocales.value = immutableArtwork.value.contentLocales
        }
      } catch (err) {
        Toast({
          message: err.message,
        })
      }

      priceInfo.value.prices = convertServerPricesToClientPrices(
        immutableArtwork.value.prices,
      )
      priceInfo.value.defaultCurrency = immutableArtwork.value.defaultCurrency
      priceInfo.value.price =
        priceInfo.value.prices[priceInfo.value.defaultCurrency]
      useWatermark.value = immutableArtwork.value.watermark.enabled
      watermarkPositions.value = immutableArtwork.value.watermark.positions
      collectible.value = immutableArtwork.value.collectible
      isR18.value = immutableArtwork.value.isR18
      hideStars.value = immutableArtwork.value.hideStars
      title.value = immutableArtwork.value.title
      content.value = immutableArtwork.value.content
      medias.value = immutableArtwork.value.pictures
        .filter((media) => {
          if (media.type === MediaType.image) {
            return true
          }

          if (media.type === MediaType.video) {
            return true
          }

          if (media.type === MediaType.file) {
            return true
          }

          return false
        })
        .map((media) => {
          const url = new URL(media.url)
          const fullPath = url.searchParams.get('path')
            ? String(url.searchParams.get('path'))
            : url.pathname
          const path = fullPath.split('/').slice(0, 3).join('/')
          return {
            ...media,
            // '/6452266be6072d756ae2918b/fc7925d0c757c07e889facc05fea0997/pjpeg'  =>
            // '/6452266be6072d756ae2918b/fc7925d0c757c07e889facc05fea0997'
            path,
          }
        })

      textMedias.value = immutableArtwork.value.pictures.filter(
        (media) => media.type === MediaType.text,
      )
    } catch (err) {
      Toast({
        message: err.message,
      })
    }
  }

  const replaceLocalMedia = async function (index, file) {
    try {
      const type = getFileType(file)

      let newMedia = null

      const preview = {
        file,
        type,
        canBrowse: medias.value[index].canBrowse,
        filename: file.name,
        mime: file.type,
        thum: (await getDataURL(file))[0],
      }

      if (file.size > sliceThrehold) {
        uploads.value[index] = []
        newMedia = await uploadSlicedOne(preview, 0)
      } else {
        uploads.value[index] = 0
        newMedia = await uploadOne(preview, 0)
      }

      preview.path = newMedia.path

      medias.value[index] = preview
    } catch (err) {
      console.error(err)
      Toast({
        message: err.message,
      })
    }
  }

  const addLocalMedias = async function (files) {
    try {
      for (const file of files.values()) {
        const type = getFileType(file)
        const index = medias.value.length

        medias.value.push({
          type,
          canBrowse: immutableArtwork.value.price === 0,
          thum: (await getDataURL(file))[0],
          filename: file.name,
          mime: file.type,
        })

        const preview = {
          file,
          type,
          canBrowse: medias.value[index].canBrowse,
          filename: file.name,
          mime: file.type,
        }

        let newMedia = null

        if (file.size > sliceThrehold) {
          uploads.value[index] = []
          newMedia = await uploadSlicedOne(preview, 0)
        } else {
          uploads.value[index] = 0
          newMedia = await uploadOne(preview, 0)
        }

        medias.value[index] = {
          ...preview,
          _id: undefined,
          path: newMedia.path,
          thum: medias.value[index].thum,
        }
      }
    } catch (err) {
      console.error(err)
      Toast({
        message: err.message,
      })
    }
  }

  const submit = async function () {
    try {
      updating.value = true
      const concludedTextMedias = concludeTextMedias()

      if (
        immutableArtwork.value.type === 'article' &&
        concludedTextMedias.length === 0
      ) {
        throw new Error('Please add at lease one text block')
      }

      const updater = {
        title: title.value,
        content: content.value,
        medias: [...concludedTextMedias, ...medias.value].map((media) => {
          return {
            _id: media._id,
            path: media.path,
            type: media.type,
            canBrowse: media.canBrowse,
            filename: media.filename,
            mime: media.mime,
            textContent: media.textContent ?? '',
          }
        }),
        useWatermark: useWatermark.value,
        watermarkPositions: watermarkPositions.value,
        isR18: isR18.value,
        type: immutableArtwork.value.type,
        collectible: collectible.value,
        hideStars: hideStars.value,
      }

      if (localeEnabled.value) {
        if (defaultLocale.value) {
          titleLocales.value[defaultLocale.value] = title.value
          if (content.value) {
            contentLocales.value[defaultLocale.value] = content.value
          }
        }

        updater.titleLocales = titleLocales.value
        updater.contentLocales = contentLocales.value
      }

      if (immutableArtwork.value.price > 0) {
        updater.prices = convertClientPricesToServerPrices(
          priceInfo.value.prices,
        )
        updater.price = convertClientPriceToServerPrice(
          priceInfo.value.price,
          priceInfo.value.defaultCurrency,
        )
        updater.defaultCurrency = priceInfo.value.defaultCurrency
      }

      console.log(updater)

      await updatePost(artworkId, updater)
      await artworkStore.getArtworkById(artworkId, true)
      if (immutableArtwork.value.type === 'album') {
        router.push(`/posts/${artworkId}`)
      } else if (immutableArtwork.value.type === 'comics') {
        router.push(`/comics/${artworkId}`)
      } else if (immutableArtwork.value.type === 'article') {
        router.push(`/posts/${artworkId}`)
      }
    } catch (err) {
      console.error(err)
      Toast({
        message: err.message,
      })
    } finally {
      updating.value = false
    }
  }

  const fireFileInput = async function (index) {
    selectedIndex.value = index
    if (mediaInputRef.value) {
      mediaInputRef.value.click()
    }
  }

  const triggerFileInput = async function (event) {
    if (uploading.value) {
      return
    }
    const files = Array.from(event.target.files)

    if (files.length <= 0) {
      return
    }

    uploading.value = true
    uploads.value = []

    if (selectedIndex.value !== -1) {
      const file = files[0]
      total.value = file.size
      await replaceLocalMedia(selectedIndex.value, files[0])
    } else {
      total.value = files.reduce((prev, cur) => prev + cur.size, 0)
      await addLocalMedias(files)
    }

    total.value = 0
    uploads.value = []
    uploading.value = false

    selectedIndex.value = -1

    event.target.value = ''
  }

  onMounted(() => {
    if (immutableArtwork.value) {
      initScopedArtwork()
    } else {
      artworkStore.getArtworkById(artworkId)
    }
  })

  useSortable(mediasContainerRef, medias)

  return {
    title,
    content,
    medias,
    submit,
    updating,
    mediasContainerRef,
    mediaInputRef,
    progress,
    uploading,
    triggerFileInput,
    fireFileInput,
    useWatermark,
    watermarkPositions,
    showWatermarkPositionsPicker,
    isR18,
    collectible,
    isFree,
    titleLocales,
    contentLocales,
    localeEnabled,
    defaultLocale,
    immutableArtwork,
    priceInfo,
    textMedias,
    addTextMedia,
    hideStars,
    buttonText,
    allMediasPrepared,
  }
}
